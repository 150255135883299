import { NavLink } from "react-router-dom";
import classes from './NavigationComponent.module.css'
const Navigation = () => {

    return (
        <nav className={classes.navbar}>
            <ul>
                <li><NavLink className={({ isActive }) => isActive ? classes.activeLink : ''} to="/" end>Home</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? classes.activeLink : ''} to="/about">About</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? classes.activeLink : ''} to="/experience">Experience</NavLink></li>
                {/* <li><NavLink className={({ isActive }) => isActive ? classes.activeLink : ''} to="/skills">Skills</NavLink></li> */}
                <li><NavLink className={({ isActive }) => isActive ? classes.activeLink : ''} to="/education">Education</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? classes.activeLink : ''} to="/projects">Projects</NavLink></li> 
            </ul>
        </nav>
    )
}


export default Navigation;