import { Link } from "react-router-dom";
import { BsFacebook, BsLinkedin } from "react-icons/bs";


const SocialMediaLinks = () => {
    
    const socialMediaData = [
        { platform: 'Facebook', url: '#', icon:<BsFacebook /> },
        { platform: 'LinkedIn', url: '#', icon:<BsLinkedin /> },
    ];

    return (
        <div className="socialMedia"> 
                {socialMediaData.map((item) => (
                    <div key={item.platform}>
                        <Link to={item.url}  target="_blank"  rel="noreferrer"> 
                            {item.icon}
                        </Link>
                    </div>
                ))} 
        </div>
    );
};

export default SocialMediaLinks;