import { Helmet } from "react-helmet-async";
import BodyWrapper from "../Components/BodyWrapper";


const About = () => {

    return (
        <BodyWrapper>
         <Helmet>
                <title>H. K. Anjana Lakshan | About Me</title>
            </Helmet>
            <h1 className="mb-20">
                About Me
            </h1>
            <div className="mx-w-50">
                <p>
                    I am a highly-skilled, result-driven, responsible Fullstack Software Engineer who fulfills the client's requirements with tailormade, customized, innovative, scalable & efficient web applications, and E-commerce solutions with agile software development methodology within a timely manner.                 
                    I am a quick learner and have hands-on experience working with cross-functional teams and professionals, including technical, business, and marketing experts, in my professional life.  
                    I have practical experience in adhering to and applying the ITIL process, as well as collaborating effectively with the relevant operational teams.
                </p>
            </div>
        </BodyWrapper>
    )
}


export default About;