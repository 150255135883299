import BodyWrapper from "../Components/BodyWrapper";
import PositionItem from "../Components/PositionItem";
import { Helmet } from "react-helmet-async";

const Experience = () => {
    const experiences = [
        {
            time: '2021 - present',
            companyName: 'Wiley',
            designation: 'Automation (Software) Engineer', 
            prevPosition: [''],
            toolsTechnologies: ['PHP','Wordpress', 'React', 'Cloudflare', 'AWS (S3, Cloudsearch, Lambda)', 'Jenkins', 'Git', 'Github', 'Graph APIs', 'Robot Framework', 'Power Automate', 'SNOW', 'ITIL Process', 'MySQL', 'HTML', 'CSS', 'JavaScript', 'JSON', 'Ajax', 'GA4'],
            description: ' Providing sustained application development support, testing and deploying new features to improve functionality and performance. Serving as a Subject Matter Expert (SME), providing ongoing support to stakeholders to efficiently carry out their business-as-usual (BAU) functions. Maintaining a strong relationship with internal and external technology, business teams, and stakeholders to ensure the delivery of valuable products. Following the ITIL process, delivering the best service possible to meet stakeholders\' needs. Actively collaborating with cross-functional and globally dispersed teams, leveraging diverse perspectives and expertise for successful project outcomes.            '
        },
        {
            time: '2015 - 2021',
            companyName: 'Living Dreams',
            designation: 'Associate Web Developer',
            prevPosition: ['Junior Web Developer', 'Trainee Web Developer'],
            toolsTechnologies: ['Wordpress', 'Shopify', 'PHP', 'Yii framework', 'Codeigniter', 'Liquid templates', 'MySQL', 'HTML', 'CSS', 'JavaScript', 'JSON', 'Ajax', 'Bootstrap', 'Git', 'Github', 'Bitbucket'],
            description: 'Contributed in all phases of the development cycle, ensuring successful project outcomes.  Analyzed user needs and software requirements, determining the feasibility of design and functionality within time and cost constraints. Developed and deployed new features to existing systems, enhancing functionality and performance. Implemented mobile responsive user interface solutions, creating intuitive and visually engaging interfaces across various devices. Executed all visual design stages, from concept development to delivering responsive interfaces. Extended the functionalities of existing Ecommerce portals, driving online presence and sales growth. Collaborated with Project Managers and team members, fostering effective communication and seamless teamwork. Coordinated with internal resources and foreign divisions for flawless project execution. Allocated resources for projects, ensuring timely delivery within scope and budget. Reviewed current systems and presented ideas for system improvements, driving efficiency and effectiveness.'
        } 
    ];

    return (
        <BodyWrapper className="experience">
         <Helmet>
                <title>H. K. Anjana Lakshan | Experience</title>
            </Helmet>
            <h1>
                Experience
            </h1>
            <div className="mx-w-50 pb-40">
                {experiences.map((position, index) => (
                    <PositionItem key={index} position={position} />
                ))}

            </div>
        </BodyWrapper>
    )
}


export default Experience;