import BodyWrapper from "../Components/BodyWrapper";
import PositionItem from "../Components/PositionItem";
import { Helmet } from "react-helmet-async";

const Education = () => { 

    const experiences = [
        {
            time: '2017 - 2018',
            companyName: 'UCD',
            designation: 'Bachelor\'s Degree, Management Information Systems',
            prevPosition: ['University College Dublin', 'NSBM', 'Graduated with Second Class Honours – Grade 1'],
            // toolsTechnologies: ['GPA: 3.57'],
             toolsTechnologies: ['GPA: 3.57','Business Policy', 'Management of Organisations', 'Information System Application Development', 'Digital Business', 'International Marketing Management', 'Financial & Management Accounting', 'Project Management', 'Database and Information Resource Management', 'Business Analytics', 'Management of Information Systems', 'E-Commerce Infrastructure'],
            description: ''
        },
        {
            time: '2014 - 2015',
            companyName: 'NIBM',
            designation: 'Higher Diploma in Computer Based Information Systems',
            prevPosition: ['National Institute of Business Management'],
            toolsTechnologies: ['GPA: 3.05', 'Management Practices', 'Data Structure & Algorithms', 'Software Engineering', 'Computer Networking', 'Advanced Database Management System', 'Computer Architecure', 'OOP & Design','Software Quality Assurance', 'Data Warehousing & Data Mining', 'Business Statistics for IT', 'Opertaional research'],
            description: ''
        },
        {
            time: '2013 - 2014',
            companyName: 'NIBM',
            designation: 'Diploma in Computer Systems Design',
            prevPosition: ['National Institute of Business Management'],
            toolsTechnologies: ['GPA: 3.5', 'Data Structures & C', 'DBMS', 'System Analysis & Design', 'OOP with C++', 'Java Programming', 'Business information Systems'],
            description: ''
        },
        {
            time: '2005 - 2013',
            companyName: 'Prince of Wales\' College',
            designation: 'Secondary',
            prevPosition: ['Mathematics'],
            toolsTechnologies: ['Captain of the school chess team'],
            description: ''
        },
        {
            time: '2000 - 2004',
            companyName: 'Buddhagosha Maha Vidyalaya',
            designation: 'Primary',
            prevPosition: [''],
            toolsTechnologies: ['Junior Prefect'],
            description: ''
        }
    ];

    return (
        <BodyWrapper className="experience">
         <Helmet>
                <title>H. K. Anjana Lakshan | Education</title>
            </Helmet>
            <h1>
                Education
            </h1>
            <div className="mx-w-50 pb-40">
                {experiences.map((position, index) => (
                    <PositionItem key={index} position={position} />
                ))}

            </div>
        </BodyWrapper>
    )
}


export default Education;