import { Outlet } from "react-router-dom";
import Navigation from "./NavigationComponent";


const RootLayout = () => {

    return (
        <>
            <main>

                <Outlet />
            </main>
            <Navigation />
        </>
    )
}


export default RootLayout;