


import { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import BodyWrapper from "../Components/BodyWrapper";
import SocialMediaLinks from "../Components/SocialMedia";

const Home = () => {
    const techs = useMemo(() => ["PHP", "Wordpress", "Shopify", "React", "MySQL", "HTML", "CSS", "JavaScript", "JSON", "Ajax", "Bootstrap", "Git", "Github", "Bitbucket", "Cloudflare", "AWS (S3, Cloudsearch, Lightsail, Lambda)", "Jenkins", "Graph APIs", "Robot Framework", "Power Automate", "SNOW", "Yii framework", "Codeigniter", "Liquid templates"], []);

    useEffect(() => {
        const animateTechStacks = () => {
            const techStacks = document.querySelectorAll(".tech-stack");
            let index = 0;

            const interval = setInterval(() => {
                if (index === techStacks.length) {
                    index = 0; // Restart the animation from the beginning
                }

                const techStack = techStacks[index];
                techStack.classList.add("animate-border"); // Apply the border animation class

                setTimeout(() => {
                    techStack.classList.remove("animate-border"); // Remove the border animation class
                }, 2000); // 2000ms = 2s (duration of disappearance animation)

                index++;
            }, 4000); // 4000ms = 4s (duration of appearance animation)

            return () => clearInterval(interval); // Cleanup the interval on component unmount
        };

        animateTechStacks();
    }, []);

    return (

        <BodyWrapper className="home"> 
             <Helmet>
                <title>H. K. Anjana Lakshan | Home</title>
            </Helmet>
            <div className="flexRow">
                <div className="leftSide mainBanner">
                    <div className="slices">
                        {techs.map((tech, index) => (
                            <div key={index} className="slice">
                                <div className="tech-stack">
                                    <div>
                                        {tech}
                                    </div>
                                </div>
                            </div>
                        ))}



                    </div>
                </div>
                <div className="rightSide center">
                    <h1>
                        H. K. Anjana Lakshan
                    </h1>
                    <h2>
                        Software Engineer
                    </h2>
                    <h3>   Precision, Trustable tailored digital transformation with creative web development</h3>
                    
                <div>
                            <SocialMediaLinks />
                </div>
                </div>
            </div>


        </BodyWrapper>


    );
};

export default Home;
