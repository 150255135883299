import CountUp from "react-countup";
import BodyWrapper from "../Components/BodyWrapper";
import { Helmet } from "react-helmet-async"; 
const Projects = () => {


    return (
        <BodyWrapper>
            <Helmet>
                <title>H. K. Anjana Lakshan | Projects</title>
            </Helmet>
            <h1>
                Projects
            </h1>
            <div className="mx-w-50 pb-40">
                <div className="counter-section">
                    <div className="projects-counter counter"><h2><CountUp start={0} end={50} duration={2} delay={0} /> +</h2><h3> Projects</h3></div>
                    <div className="year-counter counter"><h2><CountUp start={0} end={8} duration={2} delay={0} /> +</h2> <h3> Years</h3></div>
                </div>

            </div>
        </BodyWrapper>
    )
}


export default Projects;