const PositionItem = (props) => {

    return (


        <div className="position-item" >
            <div className="time">
            {props.position.time}
            </div>
            <div className="details">
                <div className="positions">
                    <h2 className="main-positions">
                        {props.position.designation} - {props.position.companyName}

                    </h2>
                    <div className="prev-positions">
                        {props.position.prevPosition.map((item, index) => (
                            <div key={index}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="descritption">
                    {props.position.description}
                </div>
                <div className="tech-stack">
                    {props.position.toolsTechnologies.map((item, index) => (
                        <div key={index}>
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}


export default PositionItem;