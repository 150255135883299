import BodyWrapper from "../Components/BodyWrapper";
import Navigation from "../Components/NavigationComponent";
import { Helmet } from "react-helmet-async";

const Error = () => {

    return (
        <>
            <main>
                <BodyWrapper className=" inner-container ">
                <Helmet>
                <title>404 Not Found</title>
            </Helmet>
                    <h1> The requested page is unavailable </h1>

                </BodyWrapper>
            </main>
            <Navigation />
        </>
    )
}


export default Error;