 
import React from "react";

const BodyWrapper = (props) => {

    return (
        <div className="container">
                    <div className="sky">
                    <div className={props.className !== undefined ? props.className + ' inner-container ' : ' inner-container '}>
           {props.children}
        </div>
                        <div className="stars"></div>
                        <div className="stars1"></div>
                        <div className="stars2"></div> 
                    </div>
                </div>
        
    )
}


export default BodyWrapper;