import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './Pages/Home';
import RootLayout from './Components/Root';
import About from './Pages/About';
import Experience from './Pages/Experience';
import Education from './Pages/Education';
import Projects from './Pages/Projects';
import Error from './Pages/Error';
import ReactGA from "react-ga4";
import { HelmetProvider } from 'react-helmet-async';

ReactGA.initialize("G-LWTF3BHE9E");

const router = createBrowserRouter(
  [
    {
      path: '/', element: <RootLayout />, children: [
        { index: true, element: <Home /> },
        { path: 'about', element: <About /> },
        { path: 'experience', element: <Experience /> },
        { path: 'education', element: <Education /> },
        // {
        //   path: 'experience', element: <ExperienceRootLayout />, children: [
        //     { index: true, element: <Experience /> },
        //   ]
        // },
        { path: 'projects', element: <Projects /> },

      ], errorElement: <Error />
    }
  ]
)

function App() {

  return (
    <HelmetProvider >

      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
